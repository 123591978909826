<template>
  <div class="container-fluid" style="max-width: 1400px">
    <b-overlay :show="loading">
      <div class="row py-3">
        <div class="col-12">
          <router-link class="mr-3" :to="getLink('')">
            <small>INICIO</small>
          </router-link>
          <small>PRODUCTOS</small>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12 col-sm-12 col-md-3 mb-4">
          <div class="pt-2">
            <div class="row px-3">
              <div class="col-12" v-if="loadingCategories">
                <div class="" v-for="p in 8" :key="p">
                  <b-skeleton class="my-1" width="90%" />
                </div>
              </div>
              <div
                class="col-12 py-2 border-bottom click bg-white border-top border-right border-left"
                :class="{
                  'bg-light': $route.params.cname == null,
                }"
              >
                <div class="row align-items-center">
                  <div class="col py-0 px-3 ">
                    <button class="btn btn-block text-left" @click="clearCategory()">
                      <small
                        :class="{
                          'font-weight-bold': $route.params.cname == null,
                        }"
                        >Todas las categorias</small
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-12 py-2 border-bottom bg-light border-right border-left"
                v-for="cat in categories"
                :key="cat._id"
                :class="{
                  'd-none': cat._id == null,
                  'bg-light':
                    $route.params.cid == cat._id && $route.params.cname != null,
                }"
                @click="filterByCategory(cat)"
              >
                <div class="row align-items-center">
                  <div class="col py-0 px-3">
                    <router-link
                      class="w-100 d-block"
                      :to="
                        getLink(
                          `productos/categoria/${StringToSlugUrl(cat.name)}/${
                            cat._id
                          }`
                        )
                      "
                    >
                      <small
                        class="text-uppercase"
                        :class="{
                          'font-weight-bold':
                            $route.params.cid == cat._id &&
                            $route.params.cname != null,
                        }"
                        >{{ cat.name }}</small
                      >
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm py-2 border border-light rounded mt-2">
          <div class="row px-3">
            <div class="col-12 mb-2">
              <div class="row">
                <div class="col">
                  <small
                    >MOSTRANDO {{ products.length }} de
                    {{ productsTotal }}</small
                  >
                </div>
                <div class="col-md-5 ml-auto">
                  <small class="d-block text-muted">ORDEN</small>
                  <select class="form-control form-control-sm" v-model="order">
                    <option value="name">Por nombre (ascendente)</option>
                    <option value="name_desc">Por nombre (descendente)</option>
                    <option value="value">Por precio (ascendente)</option>
                    <option value="value_desc">Por precio (descendente)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12" v-if="loading">
              <div class="row">
                <div
                  class="col-12 col-md-6 col-lg-3 mb-4"
                  v-for="p in 8"
                  :key="p"
                >
                  <div class="">
                    <b-skeleton-img />
                    <div class="">
                      <b-skeleton />
                      <b-skeleton class="mt-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" v-else-if="products.length == 0">
              <div class="row">
                <div
                  class="col-12 py-5"
                
                >
                  <div class="text-center py-5">
                    <h4 class="text-center">No hay productos</h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-md-6 col-lg-4 col-xl-3 mb-4 h-100 click"
              v-else
              v-for="p in products"
              :key="p._id"
            >
              <!-- <router-link :to="getLink(`productos/${p._id}/${StringToSlugUrl(p.name)}`)"> -->
              <single-product :data="p" />
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal
      :body-class="getThemeBody()"
      :header-class="getThemeBody()"
      ref="modal"
      title-class="text-uppercase"
      size="lg"
      scrollable
      hide-footer
    >
      <product :id="pid" @success="closeModal()" :textColor="getThemeText()" />
    </b-modal>
  </div>
</template>

<script>
//import product from "./Product.vue";
const product = () => import("./Product.vue");
//import singleProduct from "../components/singleProduct";
const singleProduct = () => import("../components/singleProduct.vue");
export default {
  components: {
    product,
    singleProduct,
  },
  data() {
    return {
      loading: true,
      loadingCategories: true,
      categories: [],
      products: [],
      category: {},
      productsTotal: 0,
      pid: 0,
      order: "name",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadCategories();
    this.loadProducts();
  },
  methods: {
    clearCategory() {
      // remove cid and cname from url
      this.$router.push(this.getLink("productos"));
    },
    viewProduct(pid, pname = "") {
      //const { info } = this.$store.state;
      //this.$router.push(`/${info.webName}/p/${pid}`);
      this.pid = pid;
      if (pname) this.productName = pname;
      this.$refs.modal.show();
      try {
        this.$gtag.event("product_view", {
          event_category: "product",
          event_label: "User view product",
          value: 1,
        });
        if (this.settings.facebookPixelEnabled && this.$analytics) {
          console.log("track view content");
          this.$analytics.fbq("track", "ViewContent", {
            pid: this.pid,
            content_name: pname,
          });
        }
      } catch (error) {
        console.log("error stats");
      }
    },
    StringToSlugUrl(str) {
      // replace all ñ to n
      return str
        .toLowerCase()
        .replace(/\//g, "")
        .replace(/ /g, "-")
        .replace(/ñ/g, "n");
    },
    filterByCategory(cat) {
      this.loading = true;
      this.category = cat;
    },
    loadCategories() {
       let { webName } = this.$store.state.info;
      if(webName == undefined) return false

      this.$http
        .get(`${webName}/category`)
        .then((res) => {
          this.categories = res.data.data;
        })
        .finally(() => {
          this.loadingCategories = false;
        });
    },
    loadProducts() {
      // this.loading = true;
      let { webName } = this.$store.state.info;
      let { cid } = this.$route.params;
      let query = {
        order: this.order,
      };
      if (cid) query.category = cid;

      const u = new URLSearchParams(query).toString();
      
      this.$http
        .get(`${webName}/product?${u}`)
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadPage() {
      let {webName} = this.$store.state.info;

      this.$http
        .get(`${webName}/page/_productfooter`)
        .then((res) => {
          console.log('settings components')
          this.$store.commit("setComponents", res.data.data.components)
        })
    },
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    order() {
      this.$router.push({
        query: {
          order: this.order,
        },
      });
    },
    info() {
      this.loadProducts();
      this.loadCategories();
      this.loadPage()
    },
    $route() {
      this.loadProducts();
    },
  },
};
</script>